.navbar-main-wrapper {
  flex: 1;
  height: 79px;
  display: flex;
  justify-content: center;
}

.navbar-container-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1800px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: var(--spacing-6x) 0;
  gap: var(--spacing-12x);
}

.navbar-logo-wrapper {
  width: 112px;
  height: 56px;
  display: flex;
}

.navbar-logo-wrapper img {
  object-fit: contain;
  object-position: left;
  width: 100%;
  height: 56px;
}

.navbar-button-wrapper Button {
  font-weight: 600;
  width: 120px;
  margin: 0 var(--spacing-3x);
}

.right-side-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-15x);
  justify-content: center;
}

.navigation-menu-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-5x);
  justify-content: center;
}

.navigation-menu-wrapper .menu {
  cursor: pointer;
  color: var(--md-ref-palette-primary40);
  border-radius: var(--spacing-1x);
  font-weight: var(--fontWeight-bold);
  padding: var(--spacing-3x) var(--spacing-4x);
}

.navigation-menu-wrapper .menu.active {
  background-color: var(--md-sys-color-primary-container-light);
}

.navigation-menu-wrapper .menu.inactive:hover {
  color: var(--md-ref-palette-primary20);
}

.employees-search-bar-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .navbar-container-wrapper {
    gap: var(--spacing-3x);
  }

  .navbar-logo-wrapper {
    width: 100px;
    height: 48px;
  }

  .navbar-logo-wrapper img {
    height: 48px;
  }
}

@media (max-width: 480px) {
  .navbar-container-wrapper {
    padding: 24px 0;
  }
}
