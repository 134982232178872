.employee-profile-header-wrapper {
  top: 0;
  z-index: 10;
  height: 164px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}

.employee-profile-header-wrapper h1 {
  margin: 0;
}

.employee-profile-header-inner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1800px;
  align-items: center;
  box-sizing: border-box;
  gap: var(--spacing-7x);
  padding: var(--spacing-6x) 68px;
}

.employee-profile-header-inner-wrapper .header {
  display: flex;
}

.employee-profile-header-avatar-content-wrapper {
  display: flex;
  gap: var(--spacing-6x);
}

.employee-profile-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
}

.employee-profile-content-wrapper
  .sentiment-wrapper
  .sentiment-breakdown-progress-bar-wrapper {
  font-size: 12px;
}

.employee-profile-content-wrapper
  .sentiment-wrapper
  .sentiment-breakdown-progress-bar-wrapper
  .details {
  font-weight: normal;
}

.employee-profile-content-wrapper img {
  width: var(--spacing-6x);
  height: var(--spacing-6x);
}

.employee-profile-content-wrapper .name-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-4x);
}

.employee-profile-content-wrapper .info-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-6x);
}

.employee-profile-content-wrapper .info-wrapper div {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.employee-profile-content-wrapper .sentiment-wrapper {
  margin-top: var(--spacing-3x);
}

.employee-profile-body-wrapper {
  width: 100%;
  display: flex;
  padding: 0 24px;
  overflow: hidden;
  justify-content: center;
  height: calc(100vh - 80px);
  border-bottom: 24px solid transparent;
  background-color: var(--md-ref-palette-neutral98);
}

.employee-profile-body-inner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 0 0;
  max-width: 1800px;
}

.employee-profile-body-inner-wrapper .employee-profile-body-sub-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
  flex: 1;
}

.employee-profile-body-inner-wrapper .half-width-wrapper {
  display: flex;
  gap: var(--spacing-8x);
}

.employee-profile-body-inner-wrapper .half-width-wrapper .half-width {
  flex-basis: 50%;
}

.employee-summary-history-wrapper {
  background-color: var(--white);
}

.employee-summary-history-inner-wrapper {
  padding: 0;
  height: 450px;
  display: flex;
}

.employee-summary-history-inner-wrapper .employee-summary-timeline-wrapper {
  overflow: auto;
  padding: 0 var(--spacing-4x);
  border-top: var(--spacing-4x) solid rgba(255, 255, 255, 1);
  border-bottom: var(--spacing-4x) solid rgba(255, 255, 255, 1);
}

.employee-summary-history-inner-wrapper
  .employee-summary-timeline-wrapper
  .sentiment-summary-wrapper {
  flex-basis: 0;
  justify-content: flex-end;
}

.employee-summary-history-inner-wrapper .employee-summary-questions-wrapper {
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: var(--spacing-4x);
  padding: 0 var(--spacing-4x);
  border-top: var(--spacing-4x) solid rgba(255, 255, 255, 1);
  border-bottom: var(--spacing-4x) solid rgba(255, 255, 255, 1);
}

.empty-state-wrapper,
.loader-state-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey-question-panel-first-row {
  display: flex;
  margin: 0 0 var(--spacing-5x);
  justify-content: space-between;
}

.survey-question-panel-first-row .question {
  width: 80%;
  font-size: var(--spacing-4x);
  line-height: var(--spacing-6x);
  font-weight: var(--fontWeight-bold);
}

.survey-question-panel-first-row .sentiment {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  font-size: var(--spacing-4x);
}

.survey-question-panel-second-row {
  display: flex;
  font-size: var(--spacing-3x);
  margin-top: var(--spacing-5x);
  justify-content: space-between;
}

.survey-question-panel-second-row .survey-question-panel-metadata {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.survey-question-panel-second-row
  .survey-question-panel-metadata
  .label-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

@media (max-width: 1200px) {
  .employee-profile-header-inner-wrapper {
    padding: var(--spacing-6x) 52px;
  }
}

@media (max-width: 992px) {
  .employee-profile-header-inner-wrapper {
    padding: var(--spacing-6x) 36px;
  }

  .employee-profile-body-inner-wrapper .half-width-wrapper {
    flex-wrap: wrap;
  }

  .employee-profile-body-inner-wrapper .half-width-wrapper .half-width {
    flex-basis: 100%;
  }

  .survey-question-panel-first-row .question {
    width: 75%;
    font-size: var(--spacing-4x);
    font-weight: var(--fontWeight-bold);
  }

  .survey-question-panel-first-row .sentiment {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    font-size: var(--spacing-4x);
  }

  .employee-profile-body-wrapper {
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .employee-summary-history-inner-wrapper {
    height: auto;
    min-height: 450px;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .employee-profile-body-inner-wrapper {
    padding: 16px 0 0;
  }

  .employee-profile-body-wrapper {
    padding: 0 16px;
    border-bottom: 16px solid transparent;
  }
}
