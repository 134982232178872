.chat-main-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-inner-wrapper {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding: var(--spacing-6x) var(--spacing-6x) 0;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacing-3x);
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  margin-right: var(--spacing-2x);
}

.msg-bubble {
  padding: 15px;
  max-width: 400px;
  border-radius: var(--spacing-4x);
  background: var(--md-ref-palette-neutral95);
}

.msg-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-4x);
}

.msg-info-name {
  margin-right: var(--spacing-3x);
  font-weight: var(--fontWeight-black);
}

.msg-info-time {
  font-size: var(--spacing-3x);
  font-weight: var(--fontWeight-normal);
}

.left-msg .msg-info-time {
  color: var(--md-ref-palette-secondary40);
}

.right-msg .msg-info-time {
  font-weight: var(--white);
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.msg-text {
  word-break: break-word;
  font-size: var(--spacing-4x);
  line-height: var(--spacing-7x);
  font-weight: var(--fontWeight-normal);
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  color: var(--white);
  border-bottom-right-radius: 0;
  background: var(--md-ref-palette-primary60);
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.chat-inputarea {
  bottom: 0;
  display: flex;
  position: sticky;
  align-items: center;
  padding: var(--spacing-6x);
  background-color: var(--white);
}

.chat-empty-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
