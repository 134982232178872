img.direct-at-avatar-image {
  width: 64px;
}

.card-content {
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-6x);
}
