.recognitions-main-outer-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.recognitions-main-outer-wrapper .recognitions-header-wrapper {
  display: flex;
  padding: 20px 16px;
  align-items: center;
}

.recognitions-main-outer-wrapper .recognitions-header-wrapper h2 {
  font-size: 16px;
}

.recognitions-main-outer-wrapper .recognitions-header-wrapper img {
  width: 24px;
  height: 24px;
  margin-right: var(--spacing-2x);
}

.recognitions-inner-wrapper {
  flex: 1;
  overflow: auto;
}

.recognition-list-wrapper {
  display: flex;
  padding: 24px 16px;
}

.recognition-list-wrapper .left {
  margin-right: var(--spacing-4x);
}

.recognition-list-wrapper .right {
  flex: 1;
  display: flex;
  gap: var(--spacing-2x);
  flex-direction: column;
}

.recognition-list-wrapper .right .first .title {
  display: block;
  font-size: 14px;
  font-weight: var(--fontWeight-bold);
}

.recognition-list-wrapper .right .first .info-wrapper {
  display: flex;
  font-size: 10px;
  gap: var(--spacing-4x);
}

.recognition-list-wrapper .right .first .info {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.recognition-list-wrapper .right .first .info img {
  width: 12px;
  height: 12px;
}

.recognition-list-wrapper .right .second {
  display: flex;
  gap: var(--spacing-4x);
  flex-direction: column;
}

.recognition-list-wrapper .right .second .second-first {
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
  margin-bottom: var(--spacing-2x);
}

.recognition-list-wrapper .right .second .second-second {
  display: flex;
  font-size: 10px;
  flex-wrap: wrap;
  align-items: center;
  row-gap: var(--spacing-4x);
  color: var(--md-ref-palette-secondary40);
  justify-content: space-between;
}

.recognition-list-wrapper .right .second .second-second .category {
  font-size: 10px;
  min-width: 50px;
  color: #55b67d;
  padding: 6px;
  display: inline-block;
  background-color: #ddf0e5;
  margin-right: var(--spacing-4x);
  font-weight: var(--fontWeight-semibold);
}

.recognition-list-wrapper .right .second .second-second .date {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.3);
}

.recognition-list-wrapper .right .second .second-second .date span {
  display: inline-block;
  margin-left: var(--spacing-1x);
  font-weight: var(--fontWeight-semibold);
}

.recognitions-inner-wrapper div.recognition-list-wrapper:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.recognitions-inner-wrapper .empty-state-wrapper {
  flex-direction: column;
  gap: var(--spacing-4x);
}

.recognitions-inner-wrapper .empty-state-wrapper img {
  width: 80px;
  height: 80px;
}
