.caregiver-profile-header-wrapper {
  top: 0;
  z-index: 10;
  height: 120px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}

.caregiver-profile-header-wrapper h1 {
  margin: 0;
}

.caregiver-profile-header-inner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1800px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: var(--spacing-6x) 68px;
}

.caregiver-profile-header-inner-wrapper .header {
  display: flex;
}

.caregiver-profile-body-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  height: calc(100vh - 120px);
  border-top: var(--spacing-6x) solid transparent;
  border-bottom: var(--spacing-6x) solid transparent;
  background-color: var(--md-ref-palette-neutral98);
}

.caregiver-profile-body-inner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 68px;
  max-width: 1800px;
  gap: var(--spacing-12x);
  flex-direction: column;
}

.caregiver-profile-body-inner-wrapper .half-width-wrapper {
  display: flex;
  gap: var(--spacing-8x);
}

.caregiver-profile-body-inner-wrapper .half-width-wrapper .half-width {
  flex-basis: 50%;
}

@media (max-width: 1200px) {
  .caregiver-profile-body-inner-wrapper {
    padding: 0 52px;
  }

  .caregiver-profile-header-inner-wrapper {
    padding: var(--spacing-6x) 52px;
  }
}

@media (max-width: 992px) {
  .caregiver-profile-body-inner-wrapper {
    padding: 0 36px;
  }

  .caregiver-profile-header-inner-wrapper {
    padding: var(--spacing-6x) 36px;
  }

  .caregiver-profile-body-inner-wrapper .half-width-wrapper {
    flex-wrap: wrap;
  }

  .caregiver-profile-body-inner-wrapper .half-width-wrapper .half-width {
    flex-basis: 100%;
  }
}
