.sentiment-breakdown-progress-bar-wrapper {
  gap: 6px;
  width: 100%;
  display: flex;
  font-size: 9px;
  flex-direction: column;
}

.sentiment-breakdown-progress-bar-wrapper .details {
  gap: 6px;
  display: flex;
  font-weight: 700;
  align-items: center;
  color: var(--md-ref-palette-secondary40);
}
