.caregiver-activity-panel-header-wrapper {
  padding: var(--spacing-6x);
}

.caregiver-activity-panel-body-wrapper {
  display: flex;
  height: 516px;
  overflow: auto;
  flex-direction: column;
  gap: var(--spacing-6x);
  padding: 0 var(--spacing-6x);
  border-top: var(--spacing-6x) solid transparent;
  border-bottom: var(--spacing-6x) solid transparent;
}

.caregiver-activity-panel-buttons-wrapper {
  display: flex;
  padding: var(--spacing-6x) var(--spacing-6x) 0;
}

.caregiver-activity-panel-loader-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity-panel-main-wrapper {
  padding: var(--spacing-5x);
  border-radius: var(--spacing-1x);
  background-color: var(--white);
  border: 1px solid var(--md-ref-palette-neutral80);
}

.activity-panel-first-row {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-5x);
}

.activity-panel-first-row h2 {
  text-transform: capitalize;
  font-size: var(--spacing-5x);
  margin: 0 0 var(--spacing-2x);
}

.activity-panel-first-row span {
  color: var(--md-ref-palette-secondary40);
}

.activity-panel-second-row {
  display: flex;
  font-size: var(--spacing-3x);
  margin-top: var(--spacing-5x);
  justify-content: space-between;
}

.activity-panel-second-row .activity-panel-metadata {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.activity-panel-second-row .activity-panel-metadata .label-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.activity-empty-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
