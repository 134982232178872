.recognition-banner-outer-wrapper {
  display: flex;
  padding: 16px 24px;
  color: var(--white);
  flex-direction: column;
  background-color: var(--md-ref-palette-primary60);
}

.recognition-banner-outer-wrapper .content-wrapper {
  margin: 0 0 var(--spacing-3x);
}

.recognition-banner-outer-wrapper .content-wrapper .header {
  margin-bottom: var(--spacing-3x);
}

.recognition-banner-outer-wrapper .content-wrapper .header h1 {
  margin-top: 0;
  text-align: left;
}

.recognition-banner-outer-wrapper
  .content-wrapper
  .header
  h1
  span.blue-colored {
  text-align: left;
  font-size: inherit;
  line-height: 40px;
  color: var(--md-ref-palette-primary20);
}

.recognition-banner-outer-wrapper .content-wrapper .points {
  margin: 0;
  font-size: var(--spacing-4x);
  line-height: var(--spacing-6x);
}

.recognition-banner-outer-wrapper .image-wrapper {
  display: flex;
  justify-content: flex-end;
}

.recognition-banner-outer-wrapper .image-wrapper img {
  height: 110px;
  margin-bottom: -32px;
}

@media (min-width: 481px) {
  .recognition-banner-outer-wrapper {
    padding: 40px;
    flex-direction: row;
    gap: var(--spacing-4x);
    justify-content: space-between;
  }

  .recognition-banner-outer-wrapper .content-wrapper {
    margin: 0;
  }

  .recognition-banner-outer-wrapper .image-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .recognition-banner-outer-wrapper .image-wrapper img {
    height: 140px;
    margin-bottom: -60px;
  }
}
