.document-panel-main-wrapper {
  padding: var(--spacing-5x);
  border-radius: var(--spacing-1x);
  background-color: var(--white);
  border: 1px solid var(--md-ref-palette-neutral80);
}

.document-panel-main-wrapper.highlighted {
  border: 1px solid var(--md-ref-palette-primary60);
}

.document-panel-first-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-5x);
}

.document-panel-first-column {
  display: flex;
}

.document-panel-first-row .document-panel-first-column {
  align-items: center;
}

.document-type-image {
  /* width: 150px;
  height: 150px; */
  width: var(--spacing-11x);
  height: var(--spacing-11x);
}

.content-wrapper {
  margin-left: var(--spacing-3x);
}

.content-wrapper h3 {
  margin: 0 0 var(--spacing-2x);
  font-size: var(--spacing-5x);
}

.content-wrapper span {
  font-size: var(--spacing-4x);
}

.document-panel-second-row {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-5x);
}

.document-panel-second-row .document-panel-first-column {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2x);
  margin-right: var(--spacing-6x);
}

.document-panel-second-row .document-panel-second-column {
  display: flex;
  gap: var(--spacing-3x);
}
