.search-employees-main-outer-wrapper {
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.search-employees-inner-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-11x) 24px;
}

.breadcrumbs-wrapper {
  margin-bottom: var(--spacing-15x);
}

.search-employees-inner-wrapper .info-wrapper {
  text-align: center;
  margin-bottom: var(--spacing-6x);
}
