.recognition-home-main-outer-wrapper {
  overflow: auto;
  height: calc(100vh - 80px);
}

.direct-at-card-outer-wrapper {
  padding: var(--spacing-11x) 24px;
}

.direct-at-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-5x);
  column-gap: var(--spacing-3x);
}

.direct-at-card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - calc(var(--spacing-3x) / 2));
}

.direct-at-card-outer-wrapper span {
  display: block;
  text-align: center;
  color: var(--md-ref-palette-secondary40);
  margin-bottom: var(--spacing-11x);
}

@media (min-width: 320px) {
  .direct-at-card-wrapper {
    column-gap: var(--spacing-5x);
  }

  .direct-at-card {
    flex-basis: calc(50% - calc(var(--spacing-5x) / 2));
  }
}
