.form-row {
  display: flex;
  gap: var(--spacing-5x);
  justify-content: space-between;
}

.form-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-column .section {
  border-radius: var(--spacing-1x);
  padding: var(--spacing-6x) var(--spacing-5x);
  border: 1px solid var(--md-ref-palette-neutral80);
}

.form-title {
  margin-bottom: var(--spacing-4x);
  font-weight: var(--fontWeight-bold);
}

.interview-decision-form-main-wrapper {
  display: flex;
  gap: var(--spacing-6x);
  flex-direction: column;
}
