h2 {
  margin: 0;
}

.caregiver-documents-panel-header-wrapper {
  padding: var(--spacing-6x);
}

.caregiver-documents-panel-body-wrapper {
  display: flex;
  height: 450px;
  overflow: auto;
  flex-direction: column;
  gap: var(--spacing-6x);
  padding: 0 var(--spacing-6x);
  border-top: var(--spacing-6x) solid transparent;
  border-bottom: var(--spacing-6x) solid transparent;
}

.caregiver-documents-panel-buttons-wrapper {
  display: flex;
  padding: var(--spacing-6x) var(--spacing-6x) 0;
}

.caregiver-documents-panel-loader-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
