.task-progress-bar-first-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-4x);
  justify-content: space-between;
}

.multi-progress-wrapper {
  flex: 1;
}

.total-task {
  font-size: var(--spacing-4x);
}

.task-progress-bar-second-row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-5x);
  margin-top: var(--spacing-5x);
}

.task-progress-indicator {
  display: flex;
  align-items: center;
}

.task-progress-indicator .task-counter {
  display: flex;
  border-radius: 50%;
  align-items: center;
  border-style: solid;
  justify-content: center;
  width: var(--spacing-12x);
  height: var(--spacing-12x);
  font-size: var(--spacing-3x);
  margin-right: var(--spacing-3x);
  font-weight: var(--fontWeight-bold);
}

.task-progress-indicator .task-label {
  color: var(--md-ref-palette-secondary40);
  font-size: var(--spacing-3x);
}
