.recognition-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.directed-at-wrapper {
  display: flex;
  padding: 24px;
  min-height: 115px;
  gap: var(--spacing-4x);
}

.directed-at-wrapper .right .name-wrapper h1 {
  margin-top: 0;
  text-align: left;
  font-weight: var(--fontWeight-bold);
}

.directed-at-wrapper .right .info-wrapper {
  display: flex;
  margin-bottom: 0;
  gap: var(--spacing-5x);
}

.directed-at-wrapper .right .info-wrapper .info {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.directed-at-wrapper .right .info-wrapper img {
  width: var(--spacing-5x);
  height: var(--spacing-5x);
}

.form-wrapper {
  flex: 1;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  gap: var(--spacing-12x);
}

.form-row.category {
  margin: 0 4px;
}
