.search-user-main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-user-header-wrapper {
  display: flex;
  padding: 16px 12px;
  align-items: center;
  justify-content: space-between;
}

.search-user-header-left-wrapper h3 {
  margin: 0;
}

.search-user-header-right-wrapper {
  gap: 12px;
  display: flex;
}

.search-user-body-wrapper {
  flex: 1;
  overflow: auto;
  height: calc(100vh - 61px);
}

.search-user-body-wrapper .empty-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 12px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.search-user-body-wrapper .empty-wrapper h4 {
  font-size: var(--spacing-5x);
  font-weight: var(--fontWeight-bold);
}

.search-user-body-wrapper .empty-wrapper span {
  text-align: center;
  color: var(--md-ref-palette-secondary40);
  line-height: var(--spacing-6x);
}

.search-user-body-wrapper .empty-wrapper img {
  width: 140px;
}

.users-list-wrapper {
  display: flex;
  flex-direction: column;
}

.user-list {
  display: flex;
  padding: 12px 16px;
  align-items: center;
}

.user-list:hover {
  cursor: pointer;
  background-color: var(--md-ref-palette-neutral98);
}

.search-user-body-wrapper .title {
  top: 0;
  z-index: 9;
  padding: 16px;
  font-size: 1rem;
  position: sticky;
  line-height: 1rem;
  background: var(--white);
  text-transform: uppercase;
  color: var(--md-ref-palette-secondary40);
  letter-spacing: 0.07272727em;
  margin-bottom: var(--spacing-4x);
  font-weight: var(--fontWeight-bold);
}
