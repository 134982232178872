h2 {
  margin: 0;
}

.employee-profile-summary-body-wrapper {
  width: 100%;
  display: flex;
  min-height: 300px;
  flex-direction: column;
  gap: var(--spacing-6x);
  padding: var(--spacing-6x) var(--spacing-6x);
}

.employee-profile-summary-buttons-wrapper {
  display: flex;
  padding: var(--spacing-6x) var(--spacing-6x) 0;
}

.employee-profile-summary-loader-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee-profile-summary-body-outer-wrapper {
  display: flex;
}

.employee-survey-chart-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 32px;
  justify-content: center;
}

.employee-breakdown-wrapper {
  background-color: var(--white);
}
