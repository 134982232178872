@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /* NOTE: Removed this with the incorporation of the refreshed UI. */
  /* font-size: 13px; */
}

html,
body,
#root,
.App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  text-decoration: none;
  box-sizing: border-box;
}

.custom-datagrid-cell-wrapper {
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
}

.header-label {
  display: block;
  color: var(--md-ref-palette-secondary40);
  font-size: var(--spacing-3x);
}

.margin-bottom-2x {
  margin-bottom: var(--spacing-2x);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: var(--spacing-2x);
  background-color: var(--md-sys-color-outline-variant-light);
}
