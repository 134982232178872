:root {
  --md-source: #259be9;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #001d32;
  --md-ref-palette-primary20: #003353;
  --md-ref-palette-primary25: #003e64;
  --md-ref-palette-primary30: #004a76;
  --md-ref-palette-primary35: #005688;
  --md-ref-palette-primary40: #00639a;
  --md-ref-palette-primary50: #007dc1;
  --md-ref-palette-primary60: #1d97e5;
  --md-ref-palette-primary70: #4fb2ff;
  --md-ref-palette-primary80: #96ccff;
  --md-ref-palette-primary90: #cee5ff;
  --md-ref-palette-primary95: #e8f2ff;
  --md-ref-palette-primary98: #f7f9ff;
  --md-ref-palette-primary99: #fcfcff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #0e1d2a;
  --md-ref-palette-secondary20: #233240;
  --md-ref-palette-secondary25: #2e3d4b;
  --md-ref-palette-secondary30: #3a4857;
  --md-ref-palette-secondary35: #455463;
  --md-ref-palette-secondary40: #51606f;
  --md-ref-palette-secondary50: #6a7989;
  --md-ref-palette-secondary60: #8392a3;
  --md-ref-palette-secondary70: #9eadbe;
  --md-ref-palette-secondary80: #b9c8da;
  --md-ref-palette-secondary90: #d5e4f7;
  --md-ref-palette-secondary95: #e8f2ff;
  --md-ref-palette-secondary98: #f7f9ff;
  --md-ref-palette-secondary99: #fcfcff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #340041;
  --md-ref-palette-tertiary20: #530c64;
  --md-ref-palette-tertiary25: #5f1b70;
  --md-ref-palette-tertiary30: #6c297c;
  --md-ref-palette-tertiary35: #7a3589;
  --md-ref-palette-tertiary40: #874296;
  --md-ref-palette-tertiary50: #a35bb1;
  --md-ref-palette-tertiary60: #bf75cd;
  --md-ref-palette-tertiary70: #dc8fe9;
  --md-ref-palette-tertiary80: #f5aeff;
  --md-ref-palette-tertiary90: #fdd6ff;
  --md-ref-palette-tertiary95: #ffebfd;
  --md-ref-palette-tertiary98: #fff7fa;
  --md-ref-palette-tertiary99: #fffbff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1a1c1e;
  --md-ref-palette-neutral20: #2f3033;
  --md-ref-palette-neutral25: #3a3b3e;
  --md-ref-palette-neutral30: #45474a;
  --md-ref-palette-neutral35: #515255;
  --md-ref-palette-neutral40: #5d5e61;
  --md-ref-palette-neutral50: #76777a;
  --md-ref-palette-neutral60: #909194;
  --md-ref-palette-neutral70: #aaabae;
  --md-ref-palette-neutral80: #c6c6c9;
  --md-ref-palette-neutral90: #e2e2e5;
  --md-ref-palette-neutral95: #f0f0f4;
  --md-ref-palette-neutral98: #f9f9fc;
  --md-ref-palette-neutral99: #fcfcff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #171c22;
  --md-ref-palette-neutral-variant20: #2c3137;
  --md-ref-palette-neutral-variant25: #373c42;
  --md-ref-palette-neutral-variant30: #42474e;
  --md-ref-palette-neutral-variant35: #4e535a;
  --md-ref-palette-neutral-variant40: #595f66;
  --md-ref-palette-neutral-variant50: #72777f;
  --md-ref-palette-neutral-variant60: #8c9198;
  --md-ref-palette-neutral-variant70: #a7acb3;
  --md-ref-palette-neutral-variant80: #c2c7cf;
  --md-ref-palette-neutral-variant90: #dee3eb;
  --md-ref-palette-neutral-variant95: #ecf1f9;
  --md-ref-palette-neutral-variant98: #f7f9ff;
  --md-ref-palette-neutral-variant99: #fcfcff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #00639a;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #cee5ff;
  --md-sys-color-on-primary-container-light: #001d32;
  --md-sys-color-secondary-light: #51606f;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #d5e4f7;
  --md-sys-color-on-secondary-container-light: #0e1d2a;
  --md-sys-color-tertiary-light: #874296;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #fdd6ff;
  --md-sys-color-on-tertiary-container-light: #340041;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fcfcff;
  --md-sys-color-on-background-light: #1a1c1e;
  --md-sys-color-surface-light: #fcfcff;
  --md-sys-color-on-surface-light: #1a1c1e;
  --md-sys-color-surface-variant-light: #dee3eb;
  --md-sys-color-on-surface-variant-light: #42474e;
  --md-sys-color-outline-light: #72777f;
  --md-sys-color-inverse-on-surface-light: #f0f0f4;
  --md-sys-color-inverse-surface-light: #2f3033;
  --md-sys-color-inverse-primary-light: #96ccff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #00639a;
  --md-sys-color-outline-variant-light: #c2c7cf;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #96ccff;
  --md-sys-color-on-primary-dark: #003353;
  --md-sys-color-primary-container-dark: #004a76;
  --md-sys-color-on-primary-container-dark: #cee5ff;
  --md-sys-color-secondary-dark: #b9c8da;
  --md-sys-color-on-secondary-dark: #233240;
  --md-sys-color-secondary-container-dark: #3a4857;
  --md-sys-color-on-secondary-container-dark: #d5e4f7;
  --md-sys-color-tertiary-dark: #f5aeff;
  --md-sys-color-on-tertiary-dark: #530c64;
  --md-sys-color-tertiary-container-dark: #6c297c;
  --md-sys-color-on-tertiary-container-dark: #fdd6ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1a1c1e;
  --md-sys-color-on-background-dark: #e2e2e5;
  --md-sys-color-surface-dark: #1a1c1e;
  --md-sys-color-on-surface-dark: #e2e2e5;
  --md-sys-color-surface-variant-dark: #42474e;
  --md-sys-color-on-surface-variant-dark: #c2c7cf;
  --md-sys-color-outline-dark: #8c9198;
  --md-sys-color-inverse-on-surface-dark: #1a1c1e;
  --md-sys-color-inverse-surface-dark: #e2e2e5;
  --md-sys-color-inverse-primary-dark: #00639a;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #96ccff;
  --md-sys-color-outline-variant-dark: #42474e;
  --md-sys-color-scrim-dark: #000000;
  /* custom: Sentiment Summary */
  --md-sys-color-strongly-positive: #00a73e;
  --md-sys-color-positive: #006e1c;
  --md-sys-color-neutral: #eb9733;
  --md-sys-color-negative: #b81f21;
  --md-sys-color-strongly-negative: #ff3300;
  --md-sys-color-fallback: #537876;
  /* custom: miscellaneous */
  --white: #ffffff;
  /* custom: Social */
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #0072b1;
}
