.search-result-item-wrapper {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: var(--spacing-4x);
}

.search-result-item-wrapper:hover {
  cursor: pointer;
  background-color: var(--md-ref-palette-neutral98);
}

.search-result-item-wrapper .details-wrapper {
  flex: 1;
}

.search-result-item-wrapper .details-wrapper span.title {
  display: block;
  font-size: 14px;
  margin-bottom: var(--spacing-2x);
  font-weight: var(--fontWeight-bold);
}

.search-result-item-wrapper .info-wrapper {
  row-gap: 2px;
  display: flex;
  font-size: 12px;
  flex-wrap: wrap;
  margin-bottom: 0;
  align-items: center;
  column-gap: var(--spacing-2x);
}

.search-result-item-wrapper .info-wrapper .info {
  display: flex;
  align-items: center;
  gap: var(--spacing-1x);
}

.search-result-item-wrapper .info-wrapper .info img {
  width: var(--spacing-5x);
  height: var(--spacing-5x);
}
