:root {
  /* Spacings */
  --spacing-0x: 0rem;
  --spacing-1x: 0.25rem;
  --spacing-2x: 0.5rem;
  --spacing-3x: 0.75rem;
  --spacing-4x: 1rem;
  --spacing-5x: 1.25rem;
  --spacing-6x: 1.5rem;
  --spacing-7x: 1.75rem;
  --spacing-8x: 2rem;
  --spacing-9x: 2.25rem;
  --spacing-10x: 2.5rem;
  --spacing-11x: 2.75rem;
  --spacing-12x: 3rem;
  --spacing-13x: 3.25rem;
  --spacing-14x: 3.5rem;
  --spacing-15x: 3.75rem;
  --spacing-16x: 4rem;

  /* Font Weights */
  --fontWeight-thin: 300;
  --fontWeight-normal: 400;
  --fontWeight-semibold: 500;
  --fontWeight-bold: 600;
  --fontWeight-black: 700;
  --fontWeight-extrablack: 900;
}
