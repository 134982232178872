h2 {
  margin: 0;
}

.caregiver-profile-summary-header-wrapper {
  padding: var(--spacing-6x);
}

.caregiver-profile-summary-body-wrapper {
  display: flex;
  min-height: 320px;
  flex-direction: column;
  gap: var(--spacing-6x);
  padding: var(--spacing-6x);
}

.caregiver-profile-summary-buttons-wrapper {
  display: flex;
  padding: var(--spacing-6x) var(--spacing-6x) 0;
}

.caregiver-profile-summary-loader-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
