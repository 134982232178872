.profile-section {
  display: flex;
  align-items: center;
  gap: var(--spacing-4x);
  padding: var(--spacing-4x);
  justify-content: flex-start;
}

.profile-section .right {
  display: flex;
  gap: var(--spacing-2x);
  flex-direction: column;
}

.profile-section .right .name-wrapper h3 {
  margin: 0;
  font-weight: var(--fontWeight-normal);
}

.profile-section .right .info-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-2x);
}

.profile-section .right .info-wrapper .info {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
  justify-content: center;
  font-size: var(--spacing-3x);
}

.profile-section .right .info-wrapper img {
  width: 12px;
  height: 12px;
}

.sentiment-section {
  display: flex;
}

.sentiment-section .left {
  display: flex;
  flex-basis: 65%;
  flex-direction: column;
  gap: var(--spacing-4x);
  justify-content: center;
  padding: var(--spacing-4x);
}

.sentiment-section .right {
  gap: 0;
  padding: 0;
  display: flex;
  flex-basis: 35%;
  flex-direction: column;
}

.sentiment-section .right .sentiment-summary,
.sentiment-section .right .sentiment-score {
  padding: var(--spacing-3x) var(--spacing-4x);
}

.sentiment-section .right .sentiment-score .header-label {
  align-items: center;
  display: inline-flex;
}

.sentiment-section .right .sentiment-score .value {
  font-size: var(--spacing-4x);
  margin-left: var(--spacing-1x);
  font-weight: var(--fontWeight-bold);
}

.survey-section {
  display: flex;
  gap: var(--spacing-3x);
  flex-direction: column;
  padding: var(--spacing-4x);
}

.survey-section .stepper-wrapper {
  width: 100%;
  overflow: auto;
}

.survey-section .stepper-wrapper .loader-state-wrapper {
  min-height: auto;
}

.loader-state-wrapper.left-aligned {
  justify-content: flex-start;
}

.survey-section .stepper-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

@media (max-width: 600px) {
  .sentiment-section {
    flex-direction: column;
  }

  .sentiment-section .right {
    flex-basis: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
}
