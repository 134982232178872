.filter-retention-main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filter-retention-header-wrapper {
  display: flex;
  padding: 16px 12px;
  align-items: center;
  justify-content: space-between;
}

.filter-retention-header-left-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
}

.filter-retention-header-left-wrapper h3 {
  margin: 0;
}

.filter-retention-header-right-wrapper {
  display: flex;
}

.filter-retention-body-wrapper {
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 61px);
  justify-content: space-between;
}

.filter-retention-body-wrapper .content {
  flex: 1;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: var(--spacing-8x);
}

.filter-retention-body-wrapper .content .custom-date-chips {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3x);
}

.filter-retention-body-wrapper .buttons {
  padding: 16px;
  display: flex;
  gap: var(--spacing-6x);
  justify-content: space-between;
}
