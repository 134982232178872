.preview-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.preview-wrapper img {
  max-width: 400px;
  max-height: 400px;
  width: auto;
  height: auto;
}

.preview-header-wrapper {
  display: flex;
  padding: var(--spacing-4x);
  flex-direction: row-reverse;
}

.document-empty-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
