.main-outer-wrapper {
  gap: 40px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
}

.logo-wrapper img {
  width: 80px;
}

.tag-line-wrapper {
  text-align: center;
}

.tag-line-wrapper h1 {
  margin: 0 0 var(--spacing-4x);
  font-size: var(--spacing-10x);
  font-weight: var(--fontWeight-bold);
  color: var(--md-ref-palette-primary60);
}

.tag-line-wrapper p {
  color: var(--md-ref-palette-secondary40);
  font-weight: var(--fontWeight-semibold);
}

.login-banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-banner-wrapper img {
  width: 160px;
}

.mobile-number-wrapper .info-wrapper {
  text-align: center;
  margin-bottom: var(--spacing-12x);
}

.mobile-number-wrapper .info-wrapper h2 {
  margin: 0 0 var(--spacing-4x);
  color: var(--md-sys-color-on-background-light);
}

.mobile-number-wrapper .info-wrapper p {
  color: var(--md-ref-palette-secondary40);
  font-weight: var(--fontWeight-semibold);
}

@media (min-width: 481px) {
  .main-outer-wrapper {
    padding: 40px;
  }
}
