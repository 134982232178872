.dashboard-main-outer-wrapper {
  display: flex;
  min-height: calc(100vh - 80px);
}

.dashboard-main-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px);
  background-color: var(--md-ref-palette-neutral98);
}

.dashboard-container-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 0 68px;
  max-width: 1800px;
  flex-direction: column;
  height: calc(100vh - 80px);
  border-top: var(--spacing-6x) solid transparent;
  border-bottom: var(--spacing-6x) solid transparent;
}

.dashboard-sidepanel {
  width: 55px;
  display: flex;
  justify-content: center;
  padding: var(--spacing-4x) 0;
  background-color: var(--white);
}

.dashboard-sidepanel-addon-wrapper {
  width: var(--spacing-10x);
  height: var(--spacing-10x);
}

.dashboard-first-row {
  display: flex;
  align-items: center;
  margin: var(--spacing-8x) 0;
  justify-content: space-between;
}

.dashboard-first-row h1 {
  margin: 0;
  color: var(--md-sys-color-on-background-light);
}

.dashboard-statistics-wrapper {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 16px;
  margin: var(--spacing-8x) 0;
}

.dashboard-statistics-wrapper .statistics-card {
  margin: 0;
  flex: 1;
}

.textfield-calendar {
  color: red;
}

.date-holder {
  display: flex;
  align-items: center;
  color: var(--md-ref-palette-secondary40);
}

.date-holder span {
  margin-left: var(--spacing-2x);
}

.dashboard-task-progress-wrapper h2 {
  margin: 0;
}

.task-list-wrapper {
  margin: var(--spacing-12x) 0 0;
}

.dialog-header {
  top: 0;
  z-index: 10;
  height: 80px;
  display: flex;
  position: sticky;
  align-items: center;
  background-color: var(--white);
  justify-content: space-between;
  padding: var(--spacing-5x) var(--spacing-6x);
}

.dialog-header h3 {
  margin: 0;
}

.dialog-header .right-side {
  display: flex;
  align-items: center;
}

.dialog-footer {
  height: 80px;
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  padding: var(--spacing-5x) var(--spacing-6x);
}

.dialog-footer.generic {
  gap: var(--spacing-4x);
  justify-content: flex-end;
}

.dialog-footer-left {
  margin-right: var(--spacing-5x);
}

.dialog-footer-right {
  display: flex;
  gap: var(--spacing-4x);
}

.interview-decision-form-wrapper,
.add-caregiver-form-wrapper {
  height: 100%;
  overflow: auto;
  margin: var(--spacing-3x) 0;
  padding: var(--spacing-3x) var(--spacing-6x);
}

.message-dialog-user-wrapper {
  gap: 12px;
  display: flex;
  align-items: center;
  margin-right: var(--spacing-2x);
}

.chatbox-wrapper {
  display: flex;
  overflow-y: hidden;
  height: calc(100% - 80px);
}

h1,
h4 {
  text-align: center;
}

.document-review-form-wrapper {
  height: 100%;
  display: flex;
  overflow: auto;
  gap: var(--spacing-6x);
  flex-direction: column;
  margin: var(--spacing-3x) 0;
  padding: var(--spacing-3x) var(--spacing-6x);
}

@media (max-width: 1200px) {
  .dashboard-container-wrapper {
    padding: 0 52px;
  }
}

@media (max-width: 992px) {
  .dashboard-container-wrapper {
    padding: 0 36px;
  }

  .dashboard-statistics-wrapper .statistics-card {
    margin: var(--spacing-3x);
    flex-basis: calc(50% - 24px);
  }
}
