.avatar-main-wrapper {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.avatar-main-wrapper span {
  margin-left: var(--spacing-2x);
}

.menu-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: var(--spacing-2x) var(--spacing-4x);
}

.menu-item:hover {
  transition: 0.3s;
  background-color: var(--md-ref-palette-neutral98);
}

.menu-item span {
  margin-left: var(--spacing-2x);
}
