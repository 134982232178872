.retention-personnel-main-outer-wrapper {
  display: flex;
  min-height: calc(100vh - 80px);
}

.retention-personnel-main-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 80px);
  background-color: var(--md-sys-color-background-light);
}

.retention-personnel-container-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  max-width: 1800px;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: 0 24px;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
}

.retention-personnel-details-wrapper {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: var(--spacing-7x);
}

.tab-content-header {
  top: 0;
  z-index: 9;
  display: flex;
  position: sticky;
  padding: 24px 16px;
  align-items: center;
  gap: var(--spacing-6x);
  justify-content: space-between;
}

.tab-content-header .right {
  display: flex;
  align-items: center;
  gap: var(--spacing-4x);
}

.tab-content-body {
  flex: 1;
  overflow: auto;
}

.tab-content-main-outer-wrapper {
  padding: 0;
}

.results-count-wrapper {
  font-size: 12px;
  text-wrap: nowrap;
  color: var(--md-ref-palette-secondary40);
}

.tab-content-main-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6x);
}

.tab-content-group-outer-wrapper {
  display: flex;
  gap: var(--spacing-6x);
  flex-direction: column;
}

.tab-content-group-wrapper {
  display: flex;
  gap: var(--spacing-6x);
  flex-direction: column;
}

.tab-content-wrapper {
  --columns: 3;
  --gap-count: 2;
  --row-gap-size: 16px;
  --column-gap-size: 16px;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  row-gap: var(--row-gap-size);
  column-gap: var(--column-gap-size);
}

.tab-content-wrapper .card {
  width: calc(
    calc(100% / var(--columns)) -
      calc(var(--column-gap-size) * var(--gap-count) / var(--columns))
  );
}

.tab-content-body .empty-state-wrapper {
  flex-direction: column;
  gap: var(--spacing-3x);
}

.tab-content-body .empty-state-wrapper .picture img {
  width: 120px;
  height: 120px;
}

@media (max-width: 1366px) {
  .tab-content-wrapper {
    --columns: 2;
    --gap-count: 1;
    --row-gap-size: 24px;
    --column-gap-size: 24px;
  }
}

@media (max-width: 992px) {
  .tab-content-wrapper {
    --columns: 1;
    --gap-count: 0;
  }
  .tab-content-header {
    padding: 16px;
  }
}

@media (max-width: 600px) {
  .retention-personnel-container-wrapper {
    padding: 0;
    border: 0;
  }
}
