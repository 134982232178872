.statistics-card-content-wrapper {
  display: flex;
  align-items: center;
}

.statistics-icon-wrapper {
  display: inline-flex;
  margin-right: var(--spacing-4x);
}

.statistics-heading-wrapper {
  color: var(--md-sys-color-on-background-light);
  font-size: var(--spacing-7x);
  margin-bottom: var(--spacing-2x);
  font-weight: var(--fontWeight-black);
}

.statistics-subheading-wrapper {
  color: var(--md-sys-color-on-background-light);
  font-size: var(--spacing-4x);
  font-weight: var(--fontWeight-normal);
}
