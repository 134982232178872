.otp-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.otp-wrapper .info-wrapper {
  text-align: center;
  margin-bottom: var(--spacing-12x);
}

.otp-wrapper .info-wrapper h2 {
  margin: 0 0 var(--spacing-4x);
  color: var(--md-sys-color-on-background-light);
}

.otp-wrapper .info-wrapper p {
  color: var(--md-ref-palette-secondary40);
  font-weight: var(--fontWeight-semibold);
}

.otp-wrapper .input-wrapper {
  display: flex;
  max-width: 500px;
  gap: var(--spacing-2x);
  flex-direction: column;
}
