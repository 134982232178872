.retention-main-outer-wrapper {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 80px);
}

.retention-main-wrapper-v2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.retention-container-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 8px 24px;
  max-width: 1800px;
  flex-direction: column;
  border-bottom: var(--spacing-6x) solid transparent;
}

.retention-first-row {
  margin: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-shrink: 0;
  padding: 0 24px;
  max-width: 1800px;
  align-items: center;
  justify-content: space-between;
}

.retention-active-employees-wrapper,
.retention-dynamic-breakdown-wrapper {
  margin-bottom: var(--spacing-12x);
}

.data-wrapper {
  display: flex;
  gap: var(--spacing-7x);
}

.data-wrapper.full-width {
  flex-direction: column;
}

.survey-analytics-wrapper {
  width: 95%;
  display: flex;
  font-size: 9px;
  align-items: center;
}

.survey-analytics-wrapper .progress-bar-wrapper {
  gap: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.survey-analytics-wrapper .progress-bar-wrapper .analytics {
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  color: var(--md-ref-palette-secondary40);
}

@media (max-width: 768px) {
  .retention-first-row h1 {
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  .retention-first-row {
    height: auto;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
  }

  .retention-container-wrapper {
    padding: 0;
  }

  .retention-main-wrapper-v2{
    height: fit-content;
  }
}
