.employee-data-div-wrapper {
  flex: 1;
}

.employee-data-div-header {
  z-index: 9;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: -4px;
  border-bottom: none;
  background: var(--white);
  padding: var(--spacing-4x);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.employee-data-div-header .left-panel {
  display: flex;
  align-items: center;
  gap: var(--spacing-3x);
}

.employee-data-div-header .left-panel img {
  width: var(--spacing-7x);
  height: var(--spacing-7x);
}

.employee-cell {
  width: 80%;
}

.terminated-employee-icon-cell {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.muted {
  opacity: 0.45;
}
