.follow-up-outer-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.follow-up-main-wrapper {
  width: 768px;
}
