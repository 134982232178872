.latest-survey-cell-wrapper {
  display: flex;
  gap: var(--spacing-8x);
  justify-content: space-between;
  padding-right: var(--spacing-5x);
}

.latest-survey-cell {
  flex: 1;
}

.divider-wrapper {
  display: flex;
  align-items: center;
}
